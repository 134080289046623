@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: #A6D1E6;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

/* All For 100vh in MUI -----> */
.viewportContainer {
    min-height: calc(100vh - 56px);
}

@media screen and (min-width: 0px) and (max-width: 599px) and (orientation: landscape) {
    .viewportContainer {
        min-height: calc(100vh - 48px);
    }
}

@media screen and (min-width: 600px) {
    .viewportContainer {
        min-height: calc(100vh - 64px);
    }
}

/* All For 100vh in MUI <----- */

.waveContainer {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.topContainer {
    background-image: url("../../public/assets/topWave.svg"), url("../../public/assets/upWave.svg");
}

.middleContainer {
    background-image: url("../../public/assets/downWave.svg"), url("../../public/assets/upWave.svg");
}

.bottomContainer {
    background-image: url("../../public/assets/downWave.svg"),
        url("../../public/assets/blob.svg"),
        url("../../public/assets/upWave.svg");
}